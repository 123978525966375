import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { treatements } from "../utils/jsonData";
import Autocomplete from "react-google-autocomplete";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import useGetAddressIO from "../Hooks/getAddressIo";
import axios from "axios";
import { getBlog } from "../redux/actions/adminActions";
import moment from "moment";
import { getAllFeaturedSalon } from "../redux/actions/userActions";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
const options = {
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,

  smartSpeed: 1000,

  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 5,
    },
  },
};

const optionsNew = {
  dots: false,
  nav: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const optionsNewslider = {
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  stagePadding: 10,
  smartSpeed: 1000,

  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};
export default function Home() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;

  const navigate = useNavigate();
  const { addressData, getAddress } = useGetAddressIO();
  const [serviceType, setServiceType] = useState();
  const [scroll, setScroll] = useState(false);
  const [location, setLocation] = useState();
  const [googleLat, setGoogleLat] = useState();
  const [googleLong, setGoogleLong] = useState();
  const [companyName, setCompanyName] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [postcode, setPostcode] = useState();

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(4);
  const dispatch = useDispatch();
  const { statics, blogs, featuredArr } = useSelector((state) => ({
    statics: state.userMgmtSlice.statics,
    blogs: state.userMgmtSlice.blogs,
    featuredArr: state.userMgmtSlice.featuredArr,
  }));

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isActiven, setIsActiven] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActiven((current) => !current);
  };

  const handleSearch = () => {
    // if (serviceType || googleLat)
    const queryParams = [
      serviceType && `service=${encodeURIComponent(serviceType)}`,
      googleLat && `lat=${encodeURIComponent(googleLat)}`,
      googleLong && `long=${encodeURIComponent(googleLong)}`,
    ]
      .filter(Boolean)
      .join("&");

    const url = `/searchsalons${queryParams ? `?${queryParams}` : ""}`;
    navigate(url);
    // else
    //   toast.error('Please select service type or city before proceed.')
  };

  const handleCompanyNameSearch = () => {
    if (companyName) navigate(`/searchsalons?company=${companyName}`);
    else toast.error("Please fill company name before proceed.");
  };

  const handleKeyEnter = async () => {
    try {
      const response = await axios.get(
        `https://api.getAddress.io/find/${postcode}?api-key=Qqlyy4gbsEWmF_jeJcnQ1w45046&expand=true`
      );
      if (response?.data) {
        navigate(
          `/searchsalons?service=${serviceType}&lat=${response?.data?.latitude}&long=${response?.data?.longitude}`
        );
      }
    } catch (err) {
      toast.error("Please enter correct postcode.");
    }
  };

  useEffect(() => {
    dispatch(
      getBlog({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
        status: 0,
      })
    );
  }, [page, limit, search]);

  useEffect(() => {
    dispatch(getAllFeaturedSalon());
  }, []);

  // console.log(featuredArr, "blogggggggggggg");

  return (
    <>
      {/* Header section start  */}
      <Helmet>
        <title>Salon Rated - Insured - Certified - Professional</title>
        <meta
          name="description"
          content="Salon Rated Salons specialising in facials, manicures, waxing, massage in your area. Hairdressers Barbers Tanning Salons. UK"
        />
      </Helmet>
      <Layout>
        <section className="banner-img">
          {/* top navbar start  */}

          {/* top navbar End  */}

          {/* bottom navbar start  */}
          <Container>
            <Row className="laptop-view">
              <Col lg={7}>
                <div className="banner-contant">
                  <div className="">
                    <div className="find-salon">
                      <img
                        src={require("../assets/images/salon-loc.svg").default}
                      />
                      <div className="find-salon-text">
                        <h1 className="RecifeDisplay ">Looking for a salon?</h1>
                        <p>Find recommendations near you...</p>
                      </div>
                    </div>
                    <div
                      className={
                        isActiven ? "select-city-main hide" : "select-city-main"
                      }
                    >
                      <div className="select-city">
                        <div className="services-types">
                          <label>Service type</label>

                          <Form.Select
                            onChange={(e) => setServiceType(e.target.value)}
                            aria-label="Default select example"
                          >
                            <option>Service type (e.g hair)</option>
                            {treatements?.map((item) => (
                              <option value={item?.label}>{item?.label}</option>
                            ))}
                          </Form.Select>
                        </div>
                        <div className="select-city-input">
                          <label>City</label>
                          {/* <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                      /> */}
                          <InputGroup className="">
                            <Autocomplete
                              style={{ width: "500px" }}
                              apiKey={"AIzaSyCAMul7MD_uJu1_hW99ZzJCoAtleH9iJeg"}
                              onPlaceSelected={(place) => {
                                console.log(place?.geometry?.location);
                                setLocation(place?.formatted_address);
                                setGoogleLat(place?.geometry?.location?.lat());
                                setGoogleLong(place?.geometry?.location?.lng());
                              }}
                              types={["address"]}
                              options={{
                                types: ["(regions)"],
                                componentRestrictions: { country: "uk" },
                              }}
                            />
                            {/* <Form.Control
                              placeholder="City"
                              aria-label="City"
                              aria-describedby="basic-addon1"
                            /> */}
                            <InputGroup.Text id="basic-addon1">
                              <img
                                src={
                                  require("../assets/images/loca-pic.svg")
                                    .default
                                }
                              />
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div className="sreach-city cursor-pointer">
                          <img
                            onClick={handleSearch}
                            src={
                              require("../assets/images/search-icon.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="banner-text-bottom">
                        <p>
                          or <span onClick={handleClick}>Click here</span> to
                          search members by name
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        isActiven
                          ? "select-location-main show"
                          : "select-location-main"
                      }
                    >
                      <div className="select-location">
                        <div className="select-city-input">
                          <label>Company Name</label>
                          <InputGroup className="">
                            <Form.Control
                              placeholder="Company Name"
                              aria-label="Company Name"
                              aria-describedby="basic-addon1"
                              onChange={(e) => setCompanyName(e.target.value)}
                            />
                            <InputGroup.Text id="basic-addon1">
                              <div className="loca-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="21"
                                  viewBox="0 0 16 21"
                                  fill="none"
                                >
                                  <path
                                    d="M8.06028 20.5262C7.8966 20.4024 7.71884 20.2926 7.5707 20.1518C5.66001 18.3377 3.9669 16.345 2.62543 14.069C1.83571 12.7295 1.2184 11.3195 0.8925 9.78818C0.128514 6.19603 1.87699 2.69131 5.20589 1.16819C8.2429 -0.220874 11.9895 0.703389 14.0668 3.36981C15.6409 5.39028 16.0426 7.66329 15.4612 10.1306C15.0799 11.7499 14.3543 13.221 13.4738 14.6198C12.1833 16.6689 10.6174 18.4902 8.86263 20.1528C8.7145 20.2931 8.53674 20.4024 8.37257 20.5258C8.26864 20.5262 8.16422 20.5262 8.06028 20.5262ZM12.381 8.15627C12.4844 5.90365 10.6757 3.97595 8.35363 3.86424C6.1015 3.75594 4.16701 5.56221 4.0519 7.88137C3.93922 10.1505 5.76055 12.0913 8.09816 12.1943C10.3498 12.2934 12.2746 10.4788 12.381 8.15627Z"
                                    fill="#B9B5AB"
                                  />
                                </svg>
                              </div>
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <div
                          onClick={handleCompanyNameSearch}
                          className="sreach-city cursor-pointer"
                        >
                          <img
                            src={
                              require("../assets/images/search-icon.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="banner-text-bottom">
                        <p>
                          or <span onClick={handleClick}>Search</span> for a
                          Salon near you
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={5}></Col>
            </Row>
          </Container>
          {/* bottom navbar start  */}
        </section>

        {/* Header section End */}

        {/* popular treatments  start */}

        <section className="popular-treatments">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="treatment-head-txt">
                  <h2 className="RecifeDisplay common-heading">
                    Browse our most popular treatments
                  </h2>
                  <p>From certified and insured salons near you...</p>
                </div>
                <div className="side-treatment mt-5">
                  <OwlCarousel
                    className="owl-theme"
                    loop={true}
                    margin={10}
                    items={5}
                    {...options}
                    nav={true}
                  >
                    {statics?.treatements?.map((item) => {
                      return (
                        <div class="item treatment-items">
                          <div className="items-box">
                            <div
                              className="treat-box-icon"
                              onClick={() => {
                                handleShow();
                                let words = item?.name?.split(" ");
                                let lastWord = words[words.length - 1];
                                if (lastWord == "Treatments") {
                                  words.pop();
                                  let stringWithoutLastWord = words.join(" ");
                                  setModalTitle(stringWithoutLastWord);
                                } else {
                                  setModalTitle(item?.name);
                                }
                                setServiceType(item?.name);
                              }}
                            >
                              <img
                                src={
                                  item?.image
                                    ? BASE_URL + item?.image
                                    : require("../assets/images/hair-treat.webp")
                                }
                              />
                            </div>
                            <p>{item?.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* popular treatments  End */}

        {/* Featured Salon start */}

        <section className="featured-salon">
          <Container>
            <div className="featured-salon-h">
              <h2 className="RecifeDisplay common-heading">Featured Salon</h2>
            </div>
          </Container>
          <Container fluid className="">
            <Row>
              <Col lg={12} className="px-0">
                <Container fluid className="px-0">
                  <Slider {...optionsNew} className="owl-theme-new">
                    {featuredArr?.map((item, i) => (
                      <div key={i}>
                        <Row>
                          <Col lg={6}>
                            <img
                              src={
                                item?.featured_img
                                  ? process.env.REACT_APP_HOST_NAME +
                                    item?.featured_img
                                  : require("../assets/images/slider.webp")
                              }
                            />
                          </Col>
                          <Col lg={6} className="d-flex align-items-center">
                            <div className="featured-salon-text">
                              <h3 className="FuturaBT">
                                {item?.business_name}
                              </h3>
                              <p>{item?.city}</p>
                              <div className="featured-comment">
                                <p className="Futura-Lt-BT">
                                  {item?.business_desc}
                                </p>
                              </div>
                              <div className="view-profile-feature">
                                <Link to={`/salon/${item?.salon_slug}`}>
                                  View Profile
                                </Link>
                              </div>
                              <img
                                className="feature-star"
                                src={require("../assets/images/featured-star.webp")}
                                alt="Star"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Slider>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Featured Salon End */}

        {/* Why use a salon rated business start */}

        <section className="rated-business">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="rated-business-txt">
                  {/* <h2 className="RecifeDisplay common-heading">
                    Why use a salon rated business?
                  </h2> */}
                  <p>
                    SalonRated salons are those we've verified to be fully
                    insured, certified, and committed to providing exceptional
                    professional hair and beauty services.
                  </p>
                </div>
              </Col>
              <Col lg={10} className="mx-auto">
                <Row>
                  <Col lg={4}>
                    <div className="rated-business-box">
                      <img src={require("../assets/images/insured.png")} />
                      {/* <p>INSURED</p> */}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="rated-business-box">
                      <img src={require("../assets/images/certified.png")} />
                      {/* <p>CERTIFIED</p> */}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="rated-business-box">
                      <img src={require("../assets/images/professional.png")} />
                      {/* <p>PROFESSIONAL</p> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Why use a salon rated business end */}

        {/* What our clients say start */}

        <section className="clients-say">
          <Container>
            <Row>
              <Col lg={6}></Col>
              <Col lg={6}>
                <div className="clients-say-right">
                  <h2 className="RecifeDisplay common-heading">
                    What our members say
                  </h2>
                </div>
                <div className="">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    items={1}
                    nav={true}
                    {...optionsNewslider}
                  >
                    <div class="item clients-say-comment">
                      <div className="clients-say-text">
                        <p className="Futura-Lt-BT">
                          I chose to become a member of Salon Rated for many
                          reasons! Firstly, it reassures all of my clients that
                          I have a certain standard of practice whilst also
                          helping me to keep on top of things. This includes
                          things such as health and safety policy, the latest
                          trends, any new training opportunities, and correct
                          salon etiquette. I highly recommend!
                        </p>
                        <div className="clients-say-profile">
                          <div className="clients-p-name">
                            <h3>Cloud 12 Beauty</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item clients-say-comment">
                      <div className="clients-say-text">
                        <p className="Futura-Lt-BT">
                          To me, being salon rated has meant we can be
                          recognised for our luxurious, welcoming, safe and
                          regulated environment and know that we have satisfied
                          the highest of standards for our customers and staff.
                          The Salon Rated standards really resonated with my
                          business and I feel supported by the advice that I
                          have access to being a member of the hub.
                        </p>
                        <div className="clients-say-profile">
                          <div className="clients-p-name">
                            <h3>Lash Club The Salon</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item clients-say-comment">
                      <div className="clients-say-text">
                        <p className="Futura-Lt-BT">
                          We chose Salon Rated to be recognised as an insured,
                          certified and professional salon, ensuring all areas
                          of our business are to the highest quality. This
                          recognition also proves to our clients that we really
                          care about our hygiene, service and product usage.
                        </p>
                        <div className="clients-say-profile">
                          <div className="clients-p-name">
                            <h3>The Laquer Lounge</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* What our clients say end */}

        {/* Read Our Industry Blogs start */}
        <section className="industry-blogs">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="industry-blogs-txt">
                  <h2 className="RecifeDisplay common-heading">
                    5-Minute Read:
                  </h2>
                  <p>Stay on top of the latest hair and beauty news</p>
                </div>
              </Col>
            </Row>
            {blogs?.length > 0 && (
              <Row>
                <Col lg={6}>
                  <div
                    onClick={() =>
                      navigate(`/blogdetail/${blogs[0]?.blog_slug}`)
                    }
                    className="left-side-blog mb-5"
                  >
                    <img
                      src={
                        blogs[0]?.image
                          ? BASE_URL + blogs[0]?.image
                          : require("../assets/images/left-big-img.webp")
                      }
                    />
                    <p>
                      {moment(blogs[0]?.createdAt).format("Do MMMM") || "N/A"}
                    </p>
                    <h2 className="RecifeDisplay">{blogs[0]?.title}</h2>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="right-side-blog">
                    {blogs?.map((item, i) => {
                      return (
                        i != 0 && (
                          <>
                            <div
                              onClick={() =>
                                navigate(`/blogdetail/${item?.blog_slug}`)
                              }
                              className={
                                i != 4
                                  ? "right-side-blog-list mb-4 mobile_view"
                                  : "right-side-blog-list mb-4"
                              }
                            >
                              <img
                                src={
                                  item?.image
                                    ? BASE_URL + item?.image
                                    : require("../assets/images/blog-one-img.webp")
                                }
                              />
                              <div className="right-side-blog-text">
                                <p>
                                  {moment(item?.createdAt).format("Do MMMM") ||
                                    "N/A"}
                                </p>
                                <h3 className="RecifeDisplay">{item?.title}</h3>
                              </div>
                            </div>
                          </>
                        )
                      );
                    })}
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </section>

        {/* Read Our Industry Blogs end */}

        {/* footer section start  */}

        {/* footer section end  */}

        <Modal show={show} onHide={handleClose} className="popup-center-dspy">
          <div className="modal-set-srch">
            <div className="modal-cls-icon">
              <svg
                onClick={handleClose}
                className="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.1003 0.000888237C14.3168 0.0107536 14.5302 0.0931082 14.6914 0.238087C14.943 0.464562 15.0557 0.8343 14.9734 1.16243C14.8915 1.49013 14.6425 1.74578 14.4038 1.98469C12.5628 3.8248 10.7185 5.66191 8.87155 7.49602C10.7159 9.3357 12.5603 11.175 14.4051 13.0146C14.6395 13.2484 14.883 13.4976 14.9678 13.8176C15.0557 14.1483 14.946 14.5236 14.6939 14.7548C14.4419 14.986 14.0583 15.0628 13.7368 14.9461C13.4775 14.8522 13.2735 14.6506 13.0789 14.455C11.2247 12.593 9.37089 10.731 7.51668 8.86902C5.67789 10.7108 3.83868 12.5527 1.99989 14.3945C1.75944 14.6356 1.50141 14.8865 1.17094 14.9676C0.841759 15.0486 0.471858 14.9332 0.246832 14.6793C0.0218061 14.4254 -0.0484878 14.0441 0.0715262 13.7267C0.164537 13.48 0.355702 13.2853 0.541724 13.0983C2.40408 11.2269 4.27287 9.36229 6.14895 7.50459C4.28873 5.64818 2.42894 3.79134 0.568727 1.93493C0.432854 1.79896 0.30041 1.65742 0.192826 1.49743C0.0912428 1.34601 0.0102333 1.17358 0.000803665 0.988715C-0.00133944 0.943677 0.000803665 0.89821 0.00723298 0.853173C0.0128051 0.814998 0.0303785 0.781112 0.038951 0.743795C0.0560958 0.670448 0.0830989 0.600104 0.118675 0.534049C0.190683 0.401509 0.295695 0.287843 0.418281 0.20077C0.541724 0.113268 0.684026 0.051502 0.832758 0.0244794C0.980632 -0.00254321 1.13494 0.00517754 1.27681 0.0545045C1.28452 0.0570781 1.29267 0.0600806 1.30038 0.0630831C1.54641 0.157877 1.74058 0.34918 1.92703 0.535764C3.79239 2.40247 5.65775 4.26917 7.52311 6.13545C9.37132 4.25673 11.2303 2.38831 13.0995 0.531046C13.2902 0.341459 13.4899 0.146296 13.7424 0.0540756C13.8573 0.0128983 13.9794 -0.00425893 14.1003 0.000888237Z"
                  fill="#071523"
                />
              </svg>
            </div>
            <Modal.Body>
              <h3 className="RecifeDisplay">Find {modalTitle} Treatment</h3>
            </Modal.Body>
            <div className="modal-city-srch">
              <div className="select-city-input">
                <label className="text-black">Post Code</label>

                <InputGroup className="pop-input-cat">
                  <Form.Control
                    placeholder="Post Code"
                    aria-label="City"
                    aria-describedby="basic-addon1"
                    name="postcode"
                    onChange={(e) => {
                      setPostcode(e.target.value);
                    }}
                    value={postcode}
                  />
                  <InputGroup.Text id="basic-addon1">
                    <img
                      src={require("../assets/images/loca-pic.svg").default}
                    />
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div
                onClick={handleKeyEnter}
                className="sreach-city cursor-pointer"
              >
                <img
                  src={require("../assets/images/search-icon.svg").default}
                />
              </div>
            </div>
            {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
          </div>
        </Modal>
      </Layout>
    </>
  );
}
