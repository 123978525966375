import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Accordion from "react-bootstrap/Accordion";
import LoginLayout from "../Layout/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSalonProfileById } from "../redux/actions/userActions";
import toast from "react-hot-toast";

export default function YourPlan() {
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState();

  const { subscription_data, salonDetail } = useSelector((state) => ({
    subscription_data: state.userMgmtSlice.subscription_data,
    salonDetail: state.userMgmtSlice.salonDetail,
  }));
  useEffect(() => {
    dispatch(
      getSalonProfileById({
        id: sessionStorage.getItem("userId"),
        admin: "noclicks",
      })
    ).then((data) => {
      if (
        data?.payload?.data?.subscription &&
        data?.payload?.data?.subscriptionId
      ) {
        setSubscription(data?.payload?.data?.subscription);
      }
    });
  }, []);
  console.log(salonDetail, "subscription_data");
  const handleSubscription = async (price, type) => {
    if (salonDetail?.subscriptionId) {
      return toast.error("Yor have an ongoing subscription!");
    }

    const response = await fetch(
      `${process.env.REACT_APP_SALON_BASE_URL}/createSubscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: sessionStorage.getItem("userId"),
          priceId: price,
          subscriptionType: type,
        }),
      }
    );

    const result = await response.json();

    if (result?.session?.url) {
      window.location.href = result?.session?.url;
    } else {
      toast.error(result?.msg);
    }
  };

  const scrollToDiv = () => {
    const element = document.getElementById("targetDiv");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  console.log(salonDetail, "salonDetail");

  return (
    <>
      <LoginLayout>
        {/* Header section start  */}

        <section className="banner-img-signup">
          {/* top navbar start  */}

          {/* top navbar End  */}
        </section>

        {/* Header section End */}

        {/* Careers start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">Your Plan</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={11} className="mx-auto">
                <div className="Your-Plan-box">
                  {((salonDetail?.subscriptionId &&
                    subscription_data?.subscriptionType == 1) ||
                    (salonDetail?.subscription == 1 &&
                      salonDetail?.subscriptionId == "olduser")) && (
                    <div className="your-plan-card">
                      <Row className="align-items-center">
                        <Col lg={4}>
                          <div className="plan-detail-box-one">
                            <h3 className="RecifeDisplay">BRONZE</h3>
                            <p className="Futura-Lt-BT">Limited time offer</p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="plan-detail-box-two">
                            <h3 className="FuturaBT">What is included?</h3>
                            <p>Limited Edition Offer Free</p>
                            <ul>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Basic Listing
                              </li>
                              {/* <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Window Decal
                              </li> */}
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Access to The SalonRated Hub
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="plan-detail-box-three">
                            <div class="price-rate">
                              <h3 class="FuturaBT">
                                {/* £30.00 */}
                                Free
                                </h3>
                              {/* <span>/year</span> */}
                            </div>
                            <p class="yearli-bill">billed yearly</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {((salonDetail?.subscriptionId &&
                    subscription_data?.subscriptionType == 2) ||
                    (salonDetail?.subscription == 2 &&
                      salonDetail?.subscriptionId == "olduser")) && (
                    <div className="your-plan-card">
                      <Row className="align-items-center">
                        <Col lg={4}>
                          <div className="plan-detail-box-one">
                            <h3 className="RecifeDisplay">SILVER</h3>
                            <p className="Futura-Lt-BT">Limited time offer</p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="plan-detail-box-two">
                            <h3 className="FuturaBT">What is included?</h3>
                            <p>Limited Edition Offer Only £35.00</p>
                            <ul>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Full Listing
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Social Media Links
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Standards Guide Booklet
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Window Decal
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Access to The SalonRated Hub
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="plan-detail-box-three">
                            <div class="price-rate">
                              <h3 class="FuturaBT">£35.00</h3>
                              <span>/year</span>
                            </div>
                            <p class="yearli-bill">billed yearly</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {((salonDetail?.subscriptionId &&
                    subscription_data?.subscriptionType == 3) ||
                    (salonDetail?.subscription == 3 &&
                      salonDetail?.subscriptionId == "olduser")) && (
                    <div className="your-plan-card">
                      <Row className="align-items-center">
                        <Col lg={4}>
                          <div className="plan-detail-box-one">
                            <h3 className="RecifeDisplay">GOLD</h3>
                            <p className="Futura-Lt-BT">Limited time offer</p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="plan-detail-box-two">
                            <h3 className="FuturaBT">What is included?</h3>
                            <p>Limited Edition Offer Only £75.00</p>
                            <ul>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Full Listing
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Social Media Links
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Booking Links
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Standards Guide Booklet
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Salon Feature
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Gold Salon Plaque
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Window Decal
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Access to The SalonRated Hub
                              </li>
                              <li>
                                <span>
                                  <img
                                    src={
                                      require("../assets/images/check-subc.svg")
                                        .default
                                    }
                                  />
                                </span>
                                Free Online Course - in partnership with Navy
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="plan-detail-box-three">
                            <div class="price-rate">
                              <h3 class="FuturaBT">£75.00</h3>
                              <span>/year</span>
                            </div>
                            <p class="yearli-bill">billed yearly</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row>
                    <Col lg={12}>
                      {!salonDetail?.subscriptionId && (
                        <div className="new-plan-change">
                          <h3>Your plan has expired.</h3>
                          <p>
                            Please select a subscription and complete the
                            payment process to continue.
                          </p>
                        </div>
                      )}
                    </Col>
                    <Col lg={12}>
                      <div className="plan-change">
                        <button onClick={scrollToDiv}>
                          {salonDetail?.subscriptionId
                            ? "Change Plan"
                            : "Select plan below"}
                        </button>
                        {/* {subscription_data?.planExpires || (salonDetail?.planExpires && salonDetail?.subscriptionId == 'olduser') && (
                          <p>
                            Your plan expires on{" "}
                            {moment(subscription_data?.planExpires || salonDetail?.planExpires).format(
                              "Do MMM YYYY"
                            )}
                          </p>
                        )} */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*  Careers end */}

        {/* subscription section start  */}

        <section id="targetDiv" className="subscription-section">
          <Container>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={4}>
                    <div
                      className={
                        subscription == 1
                          ? "bronze-box set-active"
                          : "bronze-box"
                      }
                    >
                      <div className="offer-tittle">
                        <h2 className="RecifeDisplay">BRONZE</h2>
                        <p className="Futura-Lt-BT">Limited time offer</p>
                        <div className="price-rate">
                          <h3 className="FuturaBT">
                            {/* £30.00 */}
                            Free
                          </h3>
                          {/* <span>/year</span> */}
                        </div>
                        <p className="yearli-bill">billed yearly</p>
                      </div>
                      <div className="what-included">
                        <h2 className="FuturaBT">What is included?</h2>
                        <p>Limited Edition Offer Free</p>
                        <ul>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Basic Listing
                          </li>
                          {/* <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Window Decal
                          </li> */}
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Access to The SalonRated Hub
                          </li>
                        </ul>
                      </div>
                      <div className="offer-btn">
                        <button
                          onClick={() => {
                            handleSubscription(
                              "price_1QrZxqK3yHPMdDWKlPuVfWVX",
                              1
                            );
                          }}
                        >
                          {subscription == 1 ? "ACTIVE" : "SUBSCRIBE"}
                        </button>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div
                      className={
                        subscription == 2
                          ? "bronze-box set-active"
                          : "bronze-box"
                      }
                    >
                      <div className="offer-tittle">
                        <h2 className="RecifeDisplay">SILVER</h2>
                        <p className="Futura-Lt-BT">Limited time offer</p>
                        <div className="price-rate">
                          <h3 className="FuturaBT">
                            {/* £45.00 */}
                            £35.00
                            </h3>
                          <span>/year</span>
                        </div>
                        <p className="yearli-bill">billed yearly</p>
                      </div>
                      <div className="what-included">
                        <h2 className="FuturaBT">What is included?</h2>
                        <p>Limited Edition Offer Only £35.00</p>
                        <ul>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Full Listing
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Social Media Links
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Standards Guide Booklet
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Window Decal
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Access to The SalonRated Hub
                          </li>
                        </ul>
                      </div>
                      <div className="offer-btn">
                        <button
                          onClick={() => {
                            handleSubscription(
                              "price_1QrZzMK3yHPMdDWKvP91GzoI",
                              2
                            );
                          }}
                        >
                          {" "}
                          {subscription == 2 ? "ACTIVE" : "SUBSCRIBE"}
                        </button>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div
                      className={
                        subscription == 3
                          ? "bronze-box Popular-section set-active"
                          : "bronze-box Popular-section"
                      }
                    >
                      <div className="offer-tittle">
                        <spna className="Popular-tag RecifeDisplay">
                          Popular
                        </spna>
                        <h2 className="RecifeDisplay">GOLD</h2>
                        <p className="Futura-Lt-BT">Limited time offer</p>
                        <div className="price-rate">
                          <h3 className="FuturaBT">
                            {/* £90.00 */}
                            £75.00
                            </h3>
                          <span>/year</span>
                        </div>
                        <p className="yearli-bill">billed yearly</p>
                      </div>
                      <div className="what-included">
                        <h2 className="FuturaBT">What is included?</h2>
                        <p>Limited Edition Offer Only £75.00</p>
                        <ul>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Full Listing
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Social Media Links
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Booking Links
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Standards Guide Booklet
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Salon Feature
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Gold Salon Plaque
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Window Decal
                          </li>
                          <li>
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            Access to The SalonRated Hub
                          </li>
                          <li className="d-flex">
                            <span>
                              <img
                                src={
                                  require("../assets/images/check-subc.svg")
                                    .default
                                }
                              />
                            </span>
                            <p>Free Online Course - in partnership with Navy</p>
                          </li>
                        </ul>
                      </div>
                      <div className="offer-btn">
                        <button
                          onClick={() => {
                            handleSubscription(
                              "price_1Qra01K3yHPMdDWKzg1z1OYh",
                              3
                            );
                          }}
                        >
                          {" "}
                          {subscription == 3 ? "ACTIVE" : "SUBSCRIBE"}
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* subscription section End  */}

        {/* footer section start  */}

        {/* footer section end  */}
      </LoginLayout>
    </>
  );
}
