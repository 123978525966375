import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import { ProgressBar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userEditProfile } from "../redux/actions/userAuth";
import { Decryptedid } from "../utils/BcruptEncyptid";
import toast from "react-hot-toast";
import { Field } from "formik";
import { Helmet } from "react-helmet";

export default function Pricing() {
  return (
    <>
      <Helmet>
        <title>Sign Up - Salon Rated</title>
        <meta name="description" content="Not Found" />
      </Helmet>
      <Layout>
        {/* Header section start  */}

        {/* Header section End */}

        {/* Sign Up Main Text start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    See below our subscription options.
                  </h2>
                  <p>
                    You'll be able to select one of these at the end of the sign
                    up process. <br />
                    Please press continue to join SalonRated .
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mx-auto">
                <div className="sign-up-details-box ">
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col lg={4}>
                          <div className={"bronze-box "}>
                            <div className="offer-tittle">
                              <h2 className="RecifeDisplay">BRONZE</h2>
                              <p className="Futura-Lt-BT">Limited time offer</p>
                              <div className="price-rate">
                                <h3 className="FuturaBT">
                                  {/* £30.00 */}
                                  Free
                                  </h3>
                                {/* <span>/year</span> */}
                              </div>
                              <p className="yearli-bill">billed yearly</p>
                            </div>
                            <div className="what-included">
                              <h2 className="FuturaBT">What is included?</h2>
                              <p>Limited Edition Offer Free</p>
                              <ul>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Basic Listing
                                </li>
                                {/* <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Window Decal
                                </li> */}
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Access to The SalonRated Hub
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className={"bronze-box"}>
                            <div className="offer-tittle">
                              <h2 className="RecifeDisplay">SILVER</h2>
                              <p className="Futura-Lt-BT">Limited time offer</p>
                              <div className="price-rate">
                                <h3 className="FuturaBT">
                                  {/* £45.00 */}
                                  £35.00
                                </h3>
                                <span>/year</span>
                              </div>
                              <p className="yearli-bill">billed yearly</p>
                            </div>
                            <div className="what-included">
                              <h2 className="FuturaBT">What is included?</h2>
                              <p>Limited Edition Offer Only £35.00</p>
                              <ul>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Full Listing
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Social Media Links
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Standards Guide Booklet
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Window Decal
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Access to The SalonRated Hub
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className={"bronze-box Popular-section"}>
                            <div className="offer-tittle">
                              <spna className="Popular-tag RecifeDisplay">
                                Popular
                              </spna>
                              <h2 className="RecifeDisplay">GOLD</h2>
                              <p className="Futura-Lt-BT">Limited time offer</p>
                              <div className="price-rate">
                                <h3 className="FuturaBT">
                                  {/* £90.00 */}
                                  £75.00
                                </h3>
                                <span>/year</span>
                              </div>
                              <p className="yearli-bill">billed yearly</p>
                            </div>
                            <div className="what-included">
                              <h2 className="FuturaBT">What is included?</h2>
                              <p>Limited Edition Offer Only £75.00</p>
                              <ul>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Full Listing
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Social Media Links
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Booking Links
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Standards Guide Booklet
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Salon Feature
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Gold Salon Plaque
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Window Decal
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Access to The SalonRated Hub
                                </li>
                                <li className="d-flex">
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  <p>
                                    Free Online Course - in partnership with
                                    Navy
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <div className="Salon-Signup-btn">
                        <Link to="/signup">Continue to Sign Up</Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sign Up Main Text end */}

        {/* subscription section start  */}

        {/* subscription section End  */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
