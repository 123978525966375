import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import InputGroup from "react-bootstrap/InputGroup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import sliderImg from "../assets/images/left-slide-icon.svg";
import Layout from "../Layout/Layout";
import { ProgressBar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userEditProfile } from "../redux/actions/userAuth";
import { Decryptedid } from "../utils/BcruptEncyptid";
import toast from "react-hot-toast";
import { Field } from "formik";
import { Helmet } from "react-helmet";

export default function SignUpFive() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  var dcryptId = Decryptedid(atob(id));
  const [subscription, setSubscription] = useState();
  const [subscriptionPrice, setSubscriptionPrice] = useState();
  const [contactPref, setContactPref] = useState();
  console.log(dcryptId, "dcryptId");
  const handleSubscription = async () => {
    console.log(contactPref, "contactPref");
    // if(subscription){
    //   let obj = {};
    //   obj.id = dcryptId;
    //   obj.step_status = 5;
    //   obj.subscription = subscription;
    //   dispatch(userEditProfile(obj)).then((data) => {
    //     if (data?.payload?.success) {
    //       toast.success(data?.payload?.message)
    //       navigate(`/Login`);
    //     }
    //   });
    // }

    if (!contactPref) {
      return toast.error(
        "Please first confirm Salon Rated's Terms & Conditions before further proceed!"
      );
    }

    const response = await fetch(
      `${process.env.REACT_APP_SALON_BASE_URL}/createSubscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: dcryptId,
          priceId: subscriptionPrice,
          subscriptionType: subscription,
        }),
      }
    );

    const result = await response.json();

    if (result?.session?.url) {
      window.location.href = result?.session?.url;
    } else {
      toast.error(result?.msg);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign Up - Salon Rated</title>
        <meta name="description" content="Not Found" />
      </Helmet>
      <Layout>
        {/* Header section start  */}

        {/* Header section End */}

        {/* Sign Up Main Text start */}

        <section className="Sign-main-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="Sign-main-text">
                  <h2 className="RecifeDisplay common-heading">
                    Become a SalonRated Salon
                  </h2>
                  <p>
                    Becoming SalonRated ensures that every potential customer
                    can trust that you're fully insured, certified, and
                    committed to providing exceptional professional hair and
                    beauty services. This instills complete confidence in your
                    business.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mx-auto">
                <div className="sign-up-details-box choose-plan-box">
                  <div className="pagination-tab">
                    <div className="tab-dots">
                      <ul>
                        <li className="active-dot">
                          <p>Step 1</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 2</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 3</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 4</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 5</p>
                        </li>
                        <li className="active-dot">
                          {" "}
                          <p>Step 6</p>
                        </li>
                      </ul>
                    </div>
                    <ProgressBar now={100} />
                  </div>
                  <h2 className="text-center choose-plan-title">
                    CHOOSE ANY PLAN
                  </h2>
                  <Row>
                    <Col lg={12} className="mt-5">
                      <Row>
                        <Col lg={4}>
                          <div
                            className={
                              subscription == 1
                                ? "bronze-box set-active"
                                : "bronze-box"
                            }
                          >
                            <div className="offer-tittle">
                              <h2 className="RecifeDisplay">BRONZE</h2>
                              <p className="Futura-Lt-BT">Limited time offer</p>
                              <div className="price-rate">
                                <h3 className="FuturaBT">
                                  {/* £30.00 */}
                                  Free
                                </h3>
                                {/* <span>/year</span> */}
                              </div>
                              <p className="yearli-bill">billed yearly</p>
                            </div>
                            <div className="what-included">
                              <h2 className="FuturaBT">What is included?</h2>
                              <p>Limited Edition Offer Free</p>
                              <ul>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Basic Listing
                                </li>
                                {/* <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Window Decal
                                </li> */}
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Access to The SalonRated Hub
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn">
                              <button
                                onClick={() => {
                                  setSubscription(1);
                                  setSubscriptionPrice(
                                    "price_1QrZxqK3yHPMdDWKlPuVfWVX"
                                  );
                                }}
                              >
                                SELECT
                              </button>
                            </div>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div
                            className={
                              subscription == 2
                                ? "bronze-box set-active"
                                : "bronze-box"
                            }
                          >
                            <div className="offer-tittle">
                              <h2 className="RecifeDisplay">SILVER</h2>
                              <p className="Futura-Lt-BT">Limited time offer</p>
                              <div className="price-rate">
                                <h3 className="FuturaBT">
                                  £35.00
                                  </h3>
                                <span>/year</span>
                              </div>
                              <p className="yearli-bill">billed yearly</p>
                            </div>
                            <div className="what-included">
                              <h2 className="FuturaBT">What is included?</h2>
                              <p>Limited Edition Offer £35.00</p>
                              <ul>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Full Listing
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Social Media Links
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Standards Guide Booklet
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Window Decal
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Access to The SalonRated Hub
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn">
                              <button
                                onClick={() => {
                                  setSubscription(2);
                                  setSubscriptionPrice(
                                    "price_1QrZzMK3yHPMdDWKvP91GzoI"
                                  );
                                }}
                              >
                                SELECT
                              </button>
                            </div>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div
                            className={
                              subscription == 3
                                ? "bronze-box Popular-section set-active"
                                : "bronze-box Popular-section"
                            }
                          >
                            <div className="offer-tittle">
                              <spna className="Popular-tag RecifeDisplay">
                                Popular
                              </spna>
                              <h2 className="RecifeDisplay">GOLD</h2>
                              <p className="Futura-Lt-BT">Limited time offer</p>
                              <div className="price-rate">
                                <h3 className="FuturaBT">
                                  £75.00
                                  </h3>
                                <span>/year</span>
                              </div>
                              <p className="yearli-bill">billed yearly</p>
                            </div>
                            <div className="what-included">
                              <h2 className="FuturaBT">What is included?</h2>
                              <p>Limited Edition Offer Only £75.00</p>
                              <ul>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Full Listing
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Social Media Links
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Booking Links
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Standards Guide Booklet
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Salon Feature
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Gold Salon Plaque
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Window Decal
                                </li>
                                <li>
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  Access to The SalonRated Hub
                                </li>
                                <li className="d-flex">
                                  <span>
                                    <img
                                      src={
                                        require("../assets/images/check-subc.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  <p>
                                    Free Online Course - in partnership with
                                    Navy
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div className="offer-btn">
                              <button
                                onClick={() => {
                                  setSubscription(3);
                                  setSubscriptionPrice(
                                    "price_1Qra01K3yHPMdDWKzg1z1OYh"
                                  );
                                }}
                              >
                                SELECT
                              </button>
                            </div>
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="category-select" key="privacy">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="privacy_policy"
                              value={contactPref ? 1 : 0}
                              onChange={(e) => setContactPref(!contactPref)}
                              checked={contactPref}
                            />
                            <label htmlFor="privacy">
                              I confirm to SalonRated's Terms & Conditions and
                              Privacy Policy
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className="">
                      <div className="Salon-Signup-btn mt-5">
                        <Link
                          to={`/signupfour/${id}`}
                          className="me-3 back-sign"
                        >
                          Back
                        </Link>
                        <button onClick={handleSubscription}>
                          Salon Signup
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sign Up Main Text end */}

        {/* subscription section start  */}

        <section className="subscription-section">
          <Container></Container>
        </section>

        {/* subscription section End  */}

        {/* footer section start  */}

        {/* footer section end  */}
      </Layout>
    </>
  );
}
